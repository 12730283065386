// Add project-specific values here
// In the Firebase Console, click `settings` -> `project settings`,
// then add a web app (the angle brackets icon).

const config = {
  apiKey: "AIzaSyDNHVmP51ujYmB4B-2OQnPtqisXslKdp4M",
  authDomain: "lvs-prod-eu.firebaseapp.com",
  projectId: "lvs-prod-eu",
  storageBucket: "lvs-prod-eu.appspot.com",
  messagingSenderId: "588694740854",
  appId: "1:588694740854:web:92fa4c9b2f3be4ccb8e457"
};

// Where does the player reside? Used when dashboards produces player urls.
// Optionally let local webpack deveserver override this via WEBPLAYER_BASE_URL
config.publicBaseUrl = process.env.WEBPLAYER_BASE_URL || 'https://lcx-player-eu.bambuser.com';

// Where does the embed reside?
config.embedBaseUrl = process.env.EMBED_BASE_URL || `https://lcx-embed-eu.bambuser.com`;

// Where does the widgets reside?
config.widgetsBaseUrl = process.env.WIDGETS_BASE_URL || 'https://lcx-widgets-eu.bambuser.com';

// Where does the app framework reside?
config.appFrameworkBaseUrl = process.env.APP_FRAMEWORK_BASE_URL || `https://app-framework-eu.bambuser.com`;

// Base url for event stream REST API
config.eventStreamBaseUrl = `https://svc-prod-eu.liveshopping.bambuser.com/eventstream`;

// Base url for metrics REST API
config.metricsBaseUrl = `https://svc-prod-eu.liveshopping.bambuser.com/collect`;

// Base url for client API
config.clientAPIBaseUrl = process.env.API_BASE_URL || `https://svc-prod-eu.liveshopping.bambuser.com/functions/api`;

// Base url for social media API
config.socialMediaBaseUrl = `https://svc-prod-eu.liveshopping.bambuser.com/functions/socialMedia`;

// Base url for client product config
config.clientProductConfigBaseUrl = `https://svc-prod-eu.liveshopping.bambuser.com/functions/productConfig`;

// Base url for client Auth
config.clientAuthBaseUrl = process.env.AUTH_BASE_URL || `https://svc-prod-eu.liveshopping.bambuser.com/functions/auth`;

// Base url for asset proxy (used when surfing in a srcdoc iframe with minimized player)
config.assetProxyBaseUrl = `https://svc-prod-eu.liveshopping.bambuser.com/functions/assetProxy`

// Base url for widgets API
config.widgetsAPIBaseUrl = `https://svc-prod-eu.liveshopping.bambuser.com/functions/widgets`;

// Base url for App Engine widgets client API
config.widgetsAppEngineAPIBaseUrl = `https://svc-prod-eu.liveshopping.bambuser.com/widgets`;

// Base url for vod
config.vodUrl = process.env.VOD_BACKEND_BASE_URL || 'https://vod-hbma4acmea-ew.a.run.app';

// Base url for videoeditor-cloudrun
config.videoEditorCloudRunBaseUrl = process.env.VIDEOEDITOR_CLOUDRUN_BASE_URL || 'https://videoeditor-cloudrun-hbma4acmea-ew.a.run.app';

// Return url for redirecting back to the dashboard, for example after
// email verification and sso logout
config.returnUrl = process.env.HOSTING_DASHBOARDURL || `https://lcx-eu.bambuser.com`;

// GTM tag id
config.gtmTagId = 'GTM-PNMBSTP';

// Public stripe key for billing page
config.stripePublicKey = 'pk_live_51IbKuwHvV77QG0tGZ76Vs6kbXWXdbHOh2gsjj6CfL6c509RhIzXbvuwRD2MGInZPN8QCouZa3BwCsNDqhI121stT003bh71P52';

// Values for Facebook Social Media integration 
config.facebook = {
  facebookOAuthUrl: 'https://www.facebook.com/v10.0/dialog/oauth',
  clientId: '230809085500169', // ID for live app
  redirectUri: 'https://lcx-eu.bambuser.com/facebook-callback',
  instagramConfigurationId: '354518663590925'
};

// Analytics' GCP-project
config.bigQueryProjectId = 'bambuser-analytics';

// Region
config.region = 'europe-west1';

// Enable custom authentication
config.useCustomAuth = true;

// Override the BaaS rtmp ingest url
config.ingestRtmpUrl = 'rtmp://eu-west-1-ingest.bambuser.io/b-fme';

// Base url to image transformer service
config.imageTransformerBaseUrl = 'https://cdn-eu.liveshopping.bambuser.com/imageTransformer';

// Base url for client API served via CF
config.clientAPIBaseCloudFrontUrl = 'https://cdn-eu.liveshopping.bambuser.com/api';

// Base url for assets served via CF (fonts, images for customizations etc)
config.assetsCdnUrl = 'https://cdn-eu.liveshopping.bambuser.com';

// Base url for Bambuser public REST API
config.lvsPublicAPIBaseUrl = 'https://liveshopping-api-eu.bambuser.com';

// Base url for One-to-One embed and callWidget
config.callsEmbedUrl = 'https://one-to-one.bambuser.com/eu/embed.js';
config.callsWidgetUrl = 'https://one-to-one.bambuser.com/eu/call-widget';

config.transcriberUrl = 'https://transcribe-eu.bambuser.com';

// Base url for chat parts
config.chatDashboardBaseUrl = process.env.CHAT_DASHBOARD_BASE_URL || 'https://eu.dashboard.chat.bambuser.com';
config.chatWebAppBaseUrl = process.env.CHAT_WEB_APP_BASE_URL || 'https://eu.online-only.chat.bambuser.com';

module.exports = config;
